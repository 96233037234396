import { Rule } from '@wix/forms-common'
import _ from 'lodash'
import {
  COMPLEX_ADDRESS_ROLES,
  COMPLEX_FIELDS_INNER_FIELDS,
  COMPLEX_FIELDS_ROLES,
  FIELDS_ROLES,
  NON_INPUT_FIELDS_ROLES,
} from '../../constants/roles'
import { makeGeneralDropdownOption } from './preset/fields/general-fields/definitions/general-dropdown'

export const isInputField = (role: string) => FIELDS_ROLES.includes(role)

export const isAnyField = (role: string) =>
  isInputField(role) || _.includes(NON_INPUT_FIELDS_ROLES, role)

export const isComplexInnerField = (role: string) => COMPLEX_FIELDS_INNER_FIELDS.includes(role)

export const isComplexAddressInnerField = (role: string) =>
  Object.values(COMPLEX_ADDRESS_ROLES).includes(role)

export const isComplexComponent = (role: string) => COMPLEX_FIELDS_ROLES.includes(role)

export const getValidCollectionId = (formId: string, collectionId: string) => {
  if (!collectionId) return

  const [compId, realCollectionId] = collectionId.split('_')
  return !collectionId.startsWith('comp')
    ? collectionId
    : compId === formId
    ? realCollectionId
    : undefined
}

export const getPrimaryConnectionFromStructure = (componentStructure: ComponentStructure) => {
  const connections = _.get(componentStructure, 'connections.items')
  return getPrimaryConnection(connections)
}

export const getPrimaryConnection = (connections) => _.find(connections, ['isPrimary', true]) || {}

export const componentRefToString = (componentRef: ComponentRef) => {
  return `${componentRef.id}__${componentRef.type}`
}

const _calcCommonValueInArray = (a): { result; frequency } => {
  const frequency = {}
  let max = 0
  let result: string
  for (let v in a) {
    const value = _.isObject(a[v]) ? JSON.stringify(a[v]) : a[v]
    frequency[value] = (frequency[value] || 0) + 1
    if (frequency[value] > max) {
      max = frequency[value]
      result = a[v]
    }
  }
  return { result, frequency: max }
}

export const calcDominatedValueInArray = (a) => {
  const { result, frequency } = _calcCommonValueInArray(a)
  return frequency >= a.length / 2 ? result : undefined
}

export const calcSecondMostCommonValueInArray = (a) => {
  const commonValue = calcCommonValueInArray(a)
  const arrayWithoutCommonValue = _.filter(a, (value) => value !== commonValue)
  if (arrayWithoutCommonValue.length) {
    return calcCommonValueInArray(arrayWithoutCommonValue)
  }

  return commonValue
}

export const calcCommonValueInArray = (a) => _calcCommonValueInArray(a).result

export const changeFieldIdInActions = (
  fieldId: string,
  newFieldId: string,
  rules: Rule[],
): Rule[] => {
  const replaceId = (id: string) => (id === fieldId ? newFieldId : id)
  return rules.map<Rule>((rule) => ({
    ...rule,
    actions: rule.actions.map((action) =>
      _.isArray(action.compId)
        ? { ...action, compId: action.compId.map(replaceId) }
        : { ...action, compId: replaceId(action.compId) },
    ),
  }))
}

export const countryCodesToOptions = (countryCodes: {
  [key: string]: string
}): DropdownOption[] => {
  return _(countryCodes)
    .map((dialCode, countryCode) => ({
      ...makeGeneralDropdownOption(),
      value: `${countryCode} ${dialCode}`,
      text: `${countryCode} ${dialCode}`,
      selectedText: dialCode,
    }))
    .value()
}
